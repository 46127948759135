img{
    height:40px;
    width: 40px;
    margin-top: 7px;
    margin-left:30px;
    border-radius: 20px;
}
#head{
    background-color:rgb(2, 27, 52);
    height: 60px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
   
    
}
#btn1{
    
    
    color: gray;
    width: 100px;
    height: 30px;
    border: 2px solid lightblue;
    border-radius: 10px;
    color: lightblue;
    font-weight: bold;
    background-color: rgb(9, 41, 73);
   
    
}
#input1{
    width: 120px;
    background-color: rgb(9, 41, 73);
    height: 30px;
    border-radius: 10px;
    outline: none;
    border: none;
    color: white;
    font-size: 10px;
    
   

}
::placeholder{
    color: white;
    font-size: 10px;
}
img{
    border: 2px solid lightblue;
}