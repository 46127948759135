#box{
    position: absolute;
    left: 300px;
    height: 100%;
    width: 1219px;
    background-color: black;
}
#leaderboard{
  
    position: absolute;
    left: 20px;
    width: 720px;
    height: 500px;
    top: 30px;
    background-color: black;
    
    
}

ol{
    list-style: none;
  
    color: white;
    margin-top: 20px;
}
#A{
    display: flex;
    background-color: black;
    width: 800px;

    margin-top: 20px;
}
#A>li:nth-child(2){
    margin-left: 10px;
    font-size: 10px;
    margin-top: 5px;
   
}
#A>li:nth-child(3){
    margin-left: 290px;
    font-size: 10px;
    margin-top: 5px;
}

#B{
    display: flex;
    
    box-shadow: 1px 1px 1px gray;
    border-radius: 10px;
    width: 580px;
    height: 40px;
    margin-top: 10PX;
    
    background-image: linear-gradient(to left, rgba(255, 253, 253, 0), rgb(95, 78, 78));
}

#B>li:nth-child(1){
    font-size: 25px;
    font-weight: bold;
    margin-left: 20px;
}
#B>:nth-child(3){
    font-size: 12px;
    margin-left: 10px;
}
#B>img{
    margin-left: 110px;

}
#B>li:last-child{
    position: absolute;
    right: 120px;
    font-size: 10px;
    margin-top: 5px;
}



img{
    height: 25px;
    width: 25px;
}
p{
    font-size: 10px
    
}
#foot1{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid lightblue;
    border-radius: 10px;
    width: 585px;
    height: 60px;

    margin-left: 30px;

    
    background-image: linear-gradient(to left, rgba(255, 253, 253, 0), rgb(95, 78, 78));
}


input{
    border: 1px dashed lightblue;
    border-radius: 20px;
    background: transparent;
    width: 150px;

}
#icons{
    display: flex;
    justify-content: right;
    margin-right: 100px;
}
#source{
    
    margin-top: 10px;
    font-size: 13px;
    
}
#btnn{
    width: 30px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}
.set{
    width: 400px;
    margin-left: 30px;
    
    border-radius: 10px;
}
::placeholder{
    text-align: center;
    
}
