#navbar{
    background-color:rgb(2, 27, 52);
    width: 300px;
    height: 100%;
    color: white;
    box-shadow: 2px 2px 4px gray;
    text-align: center;
    position: absolute;
    left: 0px;
    
}

#btn{
    font-size: 12px;
    margin-top: 20px;
    width: 200px;
    height: 40px;
    
    
    color: gray;
    background-color: rgb(9, 41, 73);
    border: none;
    text-align: left;
   
}
#btn:hover{
    color: white;
}
#btn2{
    font-size: 12px;
    margin-left: -70px;
}
#main{
    border: 2px solid lightblue;
    width: 200px;
    margin-left: 50px;
    margin-top: 10px;
    border-radius: 5px;
}
#container{
    display: flex;
    background-color: black;
}
#leader{
    margin-left: 20px;
    margin-top: 20px;
}

.info{
    margin-top: 10px;
}
.info p{
    font-size: 12px;
    color: gray;
    margin-top: 10px;
}
